import {
  ACQUISITIONS,
  ACQUISITIONS_TITLE,
} from "../components/core/nav/NavigationItems";
import { Col, Container, Row, Table } from "react-bootstrap";

import HomeShape from "../components/core/ux/HomeShape";
import Layout from "../components/core/Layout";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const AcquisitionsPage = () => (
  <Layout page={ACQUISITIONS}>
    <HomeShape />
    <Container>
      <h2 className="text-uppercase h-muted">{ACQUISITIONS}</h2>
      <h1 className="text-uppercase h bolder">{ACQUISITIONS_TITLE}</h1>
      <div data-sal="slide-up" data-sal-duration="900">
        <h3 className="headline">
          <strong>new.space</strong> sucht attraktive Standorte für große Ziele
        </h3>
      </div>
      <Row>
        <Col lg={7}>
          {/** scroll effect broken on mobile (content won't show) */}
          <p lang="de" style={{ hyphens: "auto" }}>
            <strong>new.space</strong> entwickelt, plant und baut
            Pflegeimmobilien, Senior Living und Wohnungen mit einer nachhaltigen
            Lebenszyklusbetrachtung und einer nachhaltigen Bauweise. Bevorzugt
            suchen wir B- und C-Standorte und werden in den nächsten fünf Jahren
            ca. 420 Mio. Euro investieren.
          </p>
          <p lang="de" style={{ hyphens: "auto" }}>
            Wir entwickeln marktfähige Investments mit einer klar kalkulierbaren
            Renditeerwartung, einer nachhaltigen und transparenten
            Betriebsökologie sowie DGNB-Zertifizierungen. Zudem orientieren wir
            uns an den ESG-Kriterien (Environment, Social, Governance) für mehr
            Nachhaltigkeit beim Bauen und Wohnen.
          </p>
        </Col>
        <Col lg={5}>
          <div
            data-sal="slide-up"
            data-sal-duration="900"
            data-sal-easing="ease-out"
          >
            <StaticImage
              src={"../assets/images/final/OFFICE-1.jpg"}
              alt="About new.space"
              placeholder="blurred"
              className="grow"
              quality={100}
            />
          </div>
        </Col>
      </Row>
      <div className="table-responsive profile-table">
        <Table>
          <thead>
            <tr>
              <th>Assetklasse</th>
              <th>Standort/Lagekriterien</th>
              <th>Investitionsvolumen</th>
              <th>Rechtliche Grundlagen/Dealstruktur</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b>Pflegeimmobilien</b>
              </td>
              <td>
                <p lang="de" style={{ hyphens: "auto" }}>
                  Städte, Kommunen und Einzugsgebiete ab 10.000 Einwohnern;
                  <br />
                  die Mikrolage entscheidet nach urbanem Umfeld, Infrastruktur
                  (ÖPNV), Nahversorgung, Nähe zu Wohngebieten und kulturellen
                  Angeboten. Baugrundstücke, Grundstücke mit
                  Revitalisierungsanforderungen, Bestandsgebäude,
                  Betriebsübernahmen sowie Betreibergesellschaften.
                </p>
              </td>
              <td>
                <p lang="de" style={{ hyphens: "auto" }}>
                  Grundstücke ab 3.000 qm, BGF ab 4.500 qm
                  <br />
                  mind. 60 Betten
                </p>
              </td>
              <td>
                <p lang="de" style={{ hyphens: "auto" }}>
                  Status-quo-Baurecht, Baurechtschaffung auch durch{" "}
                  <strong>new.space</strong>;
                  <br />
                  Ankauf per Asset oder Share Deal, Joint Venture
                  <br />
                  Kaufvertrag gemäß Baurecht
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <b>
                  Senioren-Wohnungen/
                  <br />
                  Appartements
                </b>
              </td>
              <td>
                <p lang="de" style={{ hyphens: "auto" }}>
                  Top-7-Städte, B- und C-Städte, vornehmlich wachstumsstarke
                  Regionen ab 50.000 Einwohnern;
                  <br />
                  die Mikrolage ist in der City oder in Cityrandlagen mit guter
                  Infrastruktur und Nahversorgung. Baugrundstücke, Grundstücke
                  mit Revitalisierungsanforderungen.
                </p>
              </td>
              <td>
                <p>Grundstücke ab 3.000 qm, BGF ab 4.500 qm</p>
              </td>
              <td>
                <p lang="de" style={{ hyphens: "auto" }}>
                  Status-quo-Baurecht, Baurechtschaffung durch{" "}
                  <strong>new.space</strong>;
                  <br />
                  Ankauf per Asset oder Share Deal, Joint Venture
                  <br />
                  Kaufvertrag gemäß Baurecht
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <b>Wohnen/Quartiere</b>
              </td>
              <td>
                <p lang="de" style={{ hyphens: "auto" }}>
                  Top-7-Städte, B- und C-Städte, vornehmlich wachstumsstarke
                  Regionen;
                  <br />
                  die Mikrolage stellt City oder Cityrandlagen mit guter
                  Infrastruktur, Nahversorgung und Schulen dar. Baugrundstücke,
                  Grundstücke mit Revitalisierungsanforderungen, Objekte mit
                  Optimierungsbedarf sowie Quartiersentwicklungen.
                </p>
              </td>
              <td>
                <p>mind. 5.000 qm erzielbare BGF</p>
              </td>
              <td>
                <p lang="de" style={{ hyphens: "auto" }}>
                  Status-quo-Baurecht, Baurechtschaffung durch{" "}
                  <strong>new.space</strong>;
                  <br />
                  Ankauf per Asset oder Share Deal, Joint Venture;
                  <br />
                  Kaufvertrag gemäß Baurecht
                </p>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <hr className="my-4" />
      <b>
        Sie haben ein passendes Objekt, das Sie mit uns realisieren möchten?
        Schreiben Sie uns oder rufen Sie uns an – Tel. +49 561 739 888 40,
        kontakt@newspace-ag.de
      </b>
      <hr className="my-4" />
    </Container>
  </Layout>
);

export default AcquisitionsPage;
